
.IndexPage {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    min-height: 100vh;
}

.column {
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.column.right {
    display: flex;
    flex-direction: column;
}

.sub-column {
    flex: 1;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    color: white;
}

.sub-column::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
}

.sub-column .foreground-content {
    position: relative;
    z-index: 2;
}

.background {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    color: white;
    box-sizing: border-box;
}

.background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
}

.foreground-content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.foreground-content.combi {
    position: absolute;
    bottom: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Logo-img {
    width: 130px;
    height: auto;
    margin-bottom: 20px;
}

.button-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    width: 80vw;
    align-items: center;
}

.button {
    padding: 10px 20px;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s ease;
    width: 100%;
}

.button:hover {
    color: white;
}

.button.bike {
    background-color: #c21718;
}

.button.road {
    background-color: #0c517c;
}

.button.combi {
    background-color: #f7b217;
    color: black;
}

.title {
    font-size: 3rem;
    margin-bottom: 5px;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
}

.subtitle {
    font-size: 2rem;
    margin-bottom: 20px;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
}

.inform-box {
    font-size: 1rem;
    margin-bottom: 20px;
    text-align: center;
    padding: 20px;
    border-radius: 5px;
    background-color: white;
    color: black;
    font-weight: bold;
}


@media screen and (min-width: 768px) {
    .IndexPage {
        grid-template-rows: 1fr; 
        grid-template-columns: repeat(3, 1fr);
        height: 100vh;
    }

    .Logo-img {
        width: 200px;
    }

    .button-group {
        width: 100%;
    }

    .foreground-content.combi {
        max-width: 300px;
    }

    .title {
        font-size: 3.5rem;
    }

    .subtitle {
        font-size: 2.5rem;
    }
}
