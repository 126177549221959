.Distances .Distances-banner {
  display: block;
  background-color: #c21718;
}

.Distances .Distances-banner p {
  margin: 0;
  margin-left: -15px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.Distances .Distances-info-article {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Distances .table {
  font-size: 1rem;
}

.Distances .Distances-title {
  padding: 15px;
  background-color: black;
  color: white;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
  font-weight: 700;
  display: block;
  font-size: 2rem;
}

.Distances .Distances-info-title {
  margin-bottom: 40px;
  font-weight: 700;

}

.Distances .Distances-info-text {
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 400;
}

.Distances-pdf-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Distances-pdf-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

.Distances-pdf-button::before {
  content: "";
  transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #c21718;
}

.Distances-pdf-button:hover {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .Distances .Distances-info-article {
    padding: 20px;
  }

  .Distances-button-wrapper {
    display: flex;
    justify-content: center;
  }
}