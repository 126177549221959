.header-mobile {
  position: absolute;
  top: 0;
  min-height: 70px;
  width: 100%;
  z-index: 99;
}

.header-mobile .header-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0,0,0,0.4);
}

.header-mobile .Logo-wrapper {
  width: 100px;
  height: auto;
}

.header-mobile .Logo-wrapper img {
  width: 100px;
  height: 100px;
}

.header-mobile button {
  background-color: transparent;
}

.header-mobile button img {
  width: 25px;
  height: auto;
}


#flyoutMenu {
  width: 100vw;
  height: 100vh;
  background-color: #106194;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform .5s cubic-bezier(0, .52, 0, 1);
  overflow: scroll;
  z-index: 1000;
  display: flex;
  justify-content: flex-start;
  padding-top: 60px;
  flex-flow: column nowrap;
}
 
#flyoutMenu.hide {
  transform: translate3d(-100vw, 0, 0);
}
 
#flyoutMenu.show {
  transform: translate3d(0vw, 0, 0);
  overflow: hidden;
}

#flyoutMenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
 
#flyoutMenu h3 a {
  color: white;
  padding-left: 15px;
  text-decoration: none;
  padding: 0.5rem;
  display: block;
  font-size: 7vmin;
}
 
#flyoutMenu h3 a:hover {
  background-color: #941212;
}

#flyoutMenu button {
  position: absolute;
  right: 0;
  top: 23px;
  background-color: transparent;
}

#flyoutMenu button img {
  width: 25px;
  height: auto;
}

#flyoutMenu .menu-mobile-collapse {
  background-color: #941212;
}

#flyoutMenu .menu-mobile-collapse a {
  color: white;
  padding: 0.5rem 1.5rem;
}

#flyoutMenu .menu-mobile-collapse a:hover {
  background-color: #7e0f10;
}

#flyoutMenu .Menu-mobile-button-wrapper {
  display: flex;
  justify-content: center;
  width:100%;
}

#flyoutMenu .Menu-mobile-button {
  padding: 10px;
  color: #106194;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

#flyoutMenu .Menu-mobile-button::before {
  content: "";
  transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: white;
}

#flyoutMenu .Menu-mobile-button:hover {
  color: #106194;
  text-decoration: none;
}

#flyoutMenu .Header-top {
  position: absolute;
  left: 0;
  top: 0;
  background-color: white;
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
}

#flyoutMenu .Header-top .Language-switcher > button {
  background: none;
  border: none;
  font-size: 0.9em;
  color: white;
  cursor: pointer;
}

#flyoutMenu .Header-top .Language-switcher > button:hover {
  opacity: 0.6;
}

#flyoutMenu .Header-top .Language-switcher > span {
  color: white;
}
