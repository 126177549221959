.Footer .Footer-bottom {
  background-color: black;
  padding: 10px 0;
}

.Footer .Footer-bottom p {
  color: lightgray;
  margin: 0;
  font-size: 0.9em;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .Footer {
    padding-bottom: 54px;
  }
}

.Footer .Footer-pretop {
  height: 20px;
  background-color: #106194;
}