.slider-caption {
  position: absolute;
  bottom: 140px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: 700;
  font-size: 72px;
  margin: 0;
  z-index: 4;
  max-width: 80%;
}

.slider-caption-2 {
  position: absolute;
  bottom: 120px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: 700;
  font-size: 35px;
  margin: 0;
  z-index: 4;
  max-width: 80%;
}

.slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 550px;
  overflow: hidden;
  white-space: nowrap;
}

.slider-wrapper {
  position: relative;
  height: 550px;
  width: 100%;
  transition: transform ease-out 0.50s;
}

.slider-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  height: 550px;
  width: 100%;
  background-repeat: no-repeat;
}

.slide {
  display: inline-block;
  height: 550px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 60%;
}

.right-arrow,
.left-arrow {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  border-radius: 50%;
  cursor: pointer;
  transition: transform ease-in 0.1s;
}

.right-arrow:hover,
.left-arrow:hover {
  transition: transform ease-in 0.1s;
  transform: scale(1.1);
}

.cool-buttons {
  background: black;
  border: 2px solid white;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 25px;
  z-index: 3;
  color: #fff;
}

.right-arrow img {
  transform: translate(2px, 0);
}

.right-arrow img:focus {
  outline: 0;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 25px;
  z-index: 3;
  color: #fff;
}

.left-arrow img {
  transform: translate(-2px, 0);
}

.left-arrow img:focus {
  outline: 0;
}

@media screen and (max-width: 768px) {
  .slider-wrapper,
  .slider-overlay,
  .slider,
  .slide {
    height: 250px !important;
  }

  .slider-caption {
    font-size: 8vmin !important;
    bottom: 10px;
  }

  .slider-caption-2 {
    font-size: 5vmin !important;
    bottom: -8px;
  }
}
