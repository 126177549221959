.yarl__fullsize {
  width: 100%;
  height: 100%;
}
.yarl__relative {
  position: relative;
}
.yarl__portal {
  position: fixed;
  inset: 0;
  opacity: 0;
  overflow: hidden;
  z-index: var(--yarl__portal_zindex, 9999);
  transition: opacity var(--yarl__fade_animation_duration, 250ms) var(--yarl__fade_animation_timing_function, ease);
}
.yarl__portal_open {
  opacity: 1;
}
.yarl__container {
  position: absolute;
  inset: 0;
  overflow: hidden;
  background-color: var(--yarl__container_background_color, var(--yarl__color_backdrop, #000));
  outline: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  touch-action: var(--yarl__controller_touch_action, none);
  overscroll-behavior: var(--yarl__controller_overscroll-behavior, contain);
}
.yarl__carousel {
  display: flex;
  flex: 0 0 auto;
  height: 100%;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  width: calc(100% + (var(--yarl__carousel_slides_count) - 1) * (100% + var(--yarl__carousel_spacing_px, 0) * 1px + var(--yarl__carousel_spacing_percent, 0) * 1%));
  -webkit-transform: translateX(var(--yarl__swipe_offset, 0px));
          transform: translateX(var(--yarl__swipe_offset, 0px));
}
.yarl__carousel_with_slides {
  -webkit-column-gap: calc(var(--yarl__carousel_spacing_px, 0) * 1px + 100 / (100 * var(--yarl__carousel_slides_count) + (var(--yarl__carousel_slides_count) - 1) * var(--yarl__carousel_spacing_percent, 0)) * var(--yarl__carousel_spacing_percent, 0) * 1%);
     -moz-column-gap: calc(var(--yarl__carousel_spacing_px, 0) * 1px + 100 / (100 * var(--yarl__carousel_slides_count) + (var(--yarl__carousel_slides_count) - 1) * var(--yarl__carousel_spacing_percent, 0)) * var(--yarl__carousel_spacing_percent, 0) * 1%);
          column-gap: calc(var(--yarl__carousel_spacing_px, 0) * 1px + 100 / (100 * var(--yarl__carousel_slides_count) + (var(--yarl__carousel_slides_count) - 1) * var(--yarl__carousel_spacing_percent, 0)) * var(--yarl__carousel_spacing_percent, 0) * 1%);
}
.yarl__flex_center {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.yarl__slide {
  flex: 1;
  position: relative;
  padding: calc(var(--yarl__carousel_padding_px, 0) * 1px + 100 / (100 * var(--yarl__carousel_slides_count) + (var(--yarl__carousel_slides_count) - 1) * var(--yarl__carousel_spacing_percent, 0)) * var(--yarl__carousel_padding_percent, 0) * 1%);
}
[dir=rtl] .yarl__slide {
  --yarl__direction: -1;
}
.yarl__slide_image {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  touch-action: var(--yarl__controller_touch_action, none);
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
@media screen and (min-width: 800px) {
  .yarl__slide_image {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
  }
}
.yarl__slide_image_cover {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.yarl__slide_image_loading {
  opacity: 0;
}
.yarl__slide_placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  line-height: 0;
}
.yarl__slide_loading {
  color: var(--yarl__slide_icon_loading_color, var(--yarl__color_button, rgba(255, 255, 255, 0.8)));
  -webkit-animation: yarl__delayed_fadein 1s linear;
          animation: yarl__delayed_fadein 1s linear;
}
.yarl__slide_loading line {
  -webkit-animation: yarl__stroke_opacity 1s linear infinite;
          animation: yarl__stroke_opacity 1s linear infinite;
}
.yarl__slide_loading line:nth-of-type(1) {
  -webkit-animation-delay: -1.875s;
          animation-delay: -1.875s;
}
.yarl__slide_loading line:nth-of-type(2) {
  -webkit-animation-delay: -1.75s;
          animation-delay: -1.75s;
}
.yarl__slide_loading line:nth-of-type(3) {
  -webkit-animation-delay: -1.625s;
          animation-delay: -1.625s;
}
.yarl__slide_loading line:nth-of-type(4) {
  -webkit-animation-delay: -1.5s;
          animation-delay: -1.5s;
}
.yarl__slide_loading line:nth-of-type(5) {
  -webkit-animation-delay: -1.375s;
          animation-delay: -1.375s;
}
.yarl__slide_loading line:nth-of-type(6) {
  -webkit-animation-delay: -1.25s;
          animation-delay: -1.25s;
}
.yarl__slide_loading line:nth-of-type(7) {
  -webkit-animation-delay: -1.125s;
          animation-delay: -1.125s;
}
.yarl__slide_loading line:nth-of-type(8) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.yarl__slide_error {
  width: var(--yarl__slide_icon_error_size, 48px);
  height: var(--yarl__slide_icon_error_size, 48px);
  color: var(--yarl__slide_icon_error_color, red);
}
@media (prefers-reduced-motion) {
  .yarl__portal, .yarl__slide {
    transition: unset;
  }
  .yarl__slide_loading, .yarl__slide_loading line {
    -webkit-animation: unset;
            animation: unset;
  }
}
.yarl__toolbar {
  position: absolute;
  inset: 0 0 auto auto;
  display: flex;
  justify-content: flex-end;
  padding: var(--yarl__toolbar_padding, 8px);
}
[dir=rtl] .yarl__toolbar {
  inset: 0 auto auto 0;
}
.yarl__icon {
  width: var(--yarl__icon_size, 32px);
  height: var(--yarl__icon_size, 32px);
}
.yarl__button {
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--yarl__button_background_color, transparent);
  border: var(--yarl__button_border, 0);
  margin: var(--yarl__button_margin, 0);
  outline: none;
  line-height: 0;
  padding: var(--yarl__button_padding, 8px);
  color: var(--yarl__color_button, rgba(255, 255, 255, 0.8));
  -webkit-filter: var(--yarl__button_filter, drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8)));
          filter: var(--yarl__button_filter, drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8)));
  -webkit-tap-highlight-color: transparent;
}
.yarl__button:focus {
  color: var(--yarl__color_button_active, #fff);
}
.yarl__button:focus:not(:focus-visible) {
  color: var(--yarl__color_button, rgba(255, 255, 255, 0.8));
}
.yarl__button:focus-visible {
  color: var(--yarl__color_button_active, #fff);
}
@media (hover: hover) {
  .yarl__button:hover, .yarl__button:focus:hover, .yarl__button:focus-visible:hover {
    color: var(--yarl__color_button_active, #fff);
  }
}
.yarl__button:disabled {
  color: var(--yarl__color_button_disabled, rgba(255, 255, 255, 0.4));
  cursor: default;
}
.yarl__navigation_prev, .yarl__navigation_next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: var(--yarl__navigation_button_padding, 24px 16px);
}
.yarl__navigation_prev {
  left: 0;
}
[dir=rtl] .yarl__navigation_prev {
  left: unset;
  right: 0;
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}
.yarl__navigation_next {
  right: 0;
}
[dir=rtl] .yarl__navigation_next {
  left: 0;
  right: unset;
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}
.yarl__no_scroll {
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
}

@-webkit-keyframes yarl__delayed_fadein {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes yarl__delayed_fadein {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes yarl__stroke_opacity {
  from {
    stroke-opacity: 1;
  }
  to {
    stroke-opacity: 0.125;
  }
}
@keyframes yarl__stroke_opacity {
  from {
    stroke-opacity: 1;
  }
  to {
    stroke-opacity: 0.125;
  }
}

.Media .Media-banner {
  display: block;
  background-color: #c21718;
}

.Media .Media-banner p {
  margin: 0;
  margin-left: -15px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.Media .Media-info-article {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Media .table {
  font-size: 1rem;
}

.Media .Media-title {
  padding: 15px;
  background-color: black;
  color: white;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
  font-weight: 700;
  display: block;
  font-size: 2rem;
}

.Media .Media-info-title {
  margin-bottom: 40px;
  font-weight: 700;

}

.Media .Media-info-text {
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 400;
}

.Media-inscription-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Media-inscription-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

.Media-inscription-button::before {
  content: "";
  transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #c21718;
}

.Media-inscription-button:hover {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .Media .Media-info-article {
    padding: 20px;
  }

  .Media-button-wrapper {
    justify-content: center;
  }
}

.tab-header-list {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid lightgray;
}

.tab-header-item {
  border: none;
  color: #444;
  padding: 1em;
  background-color: transparent;
  font-weight: 700;
  cursor: pointer;
}

.tab-header-item:hover {
  color: #c21718;
}

.tab-header-item:active,
.tab-header-item:focus {
  outline: none;
}

.tab-header-item[data-state="active"] {
  color: #c21718;
  border-bottom: 2px solid #c21718;
}
