.Embassador .Embassador-banner {
  display: block;
  background-color: #c21718;
}

.Embassador .Embassador-banner p {
  margin: 0;
  margin-left: -15px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.Embassador .Embassador-info-article {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Embassador .table {
  font-size: 1rem;
}

.Embassador .Embassador-title {
  padding: 15px;
  background-color: black;
  color: white;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
  font-weight: 700;
  display: block;
  font-size: 2rem;
}

.Embassador .Embassador-info-title {
  margin-bottom: 40px;
  font-weight: 700;

}

.Embassador .Embassador-info-text {
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 400;
}

.Embassador-inscription-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Embassador-inscription-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

.Embassador-inscription-button::before {
  content: "";
  transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #c21718;
}

.Embassador-inscription-button:hover {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .Embassador .Embassador-info-article {
    padding: 20px;
  }

  .Embassador-button-wrapper {
    justify-content: center;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #c21718;
  background-color: rgba(194, 23, 24, 0.25);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23c21718'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #c21718;
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}